const addPainting = () => {
    const createButton = document.getElementById("addPainting");
    createButton.addEventListener("click", () => {
        // Trova il container degli ordini
        const paintingContainer = document.getElementById("paintingContainer");
        //console.log(productContainer);
        
        // Crea un nuovo indice incrementale per la nuova chiave dell'ordine
        const newIndex = paintingContainer.querySelectorAll('.painting-fields').length;
        //console.log(newIndex);
        
        // Clona il primo fieldset e sostituisci l'ID con il nuovo ID
        const visibleFieldset = paintingContainer.querySelector('.painting-fields:not([style*="display: none"])');
        //console.log(visibleFieldset);
        
        if (visibleFieldset) {
            const newFieldset = visibleFieldset.cloneNode(true);
            newFieldset.id = newIndex;
  
            // Modifica i nomi degli input all'interno del nuovo fieldset per includere il nuovo indice
            const inputs = newFieldset.querySelectorAll('input');
            inputs.forEach(input => {
                const name = input.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
                input.setAttribute('name', name);
                input.value = ''; // Svuota il valore dell'input
            });

            const selects = newFieldset.querySelectorAll('select');
            selects.forEach(select => {
                const name = select.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
                select.setAttribute('name', name);
                select.selectedIndex = 0; // Svuota il valore dell'input
            });
  
        
            // Aggiungi il nuovo fieldset al container degli ordini
            paintingContainer.appendChild(newFieldset);
  
            // Aggiorna lo stato dei link "rimuovi ordine"
            updateRemovePaintingLinks();

            
        }
    });
}
  
const updateRemovePaintingLinks = () => {
    // Trova il container degli ordini
    const paintingContainer = document.getElementById("paintingContainer");
    
    // Trova tutti gli ordini presenti
    const paintings = paintingContainer.querySelectorAll('.painting-fields');

    // Trova i link per rimuovere l'ordine
    const removePaintingLinks = document.querySelectorAll('.remove-painting-fields');

    // Verifica se ci sono più di un ordine
    if (paintings.length >= 2) {
        // Riattiva i link per rimuovere l'ordine
        removePaintingLinks.forEach(link => {
            link.classList.remove('disabled');
            link.removeAttribute('disabled');
            link.closest('.remove-painting-fields').style.display = "block"; // Mostra visivamente l'ordine
        });
    } else {
        // Disattiva i link per rimuovere l'ordine
        removePaintingLinks.forEach(link => {
            link.classList.add('disabled');
            link.setAttribute('disabled', 'disabled');
            link.closest('.remove-painting-fields').style.display = "none"; // Nasconde visivamente l'ordine
        });
    }
}
  
  export { addPainting };