const addDdtDetail = () => {
    const createButton = document.getElementById("addDdtDetail");
    createButton.addEventListener("click", () => {
        // Trova il container degli ordini
        const detailsContainer = document.getElementById("ddtdetailsContainer");
        //console.log(productContainer);
        
        // Crea un nuovo indice incrementale per la nuova chiave dell'ordine
        const newIndex = detailsContainer.querySelectorAll('.ddt-detail-fields').length;
        //console.log(newIndex);
        
        // Clona il primo fieldset e sostituisci l'ID con il nuovo ID
        const visibleFieldset = detailsContainer.querySelector('.ddt-detail-fields:not([style*="display: none"])');
        //console.log(visibleFieldset);
        
        if (visibleFieldset) {
            const newFieldset = visibleFieldset.cloneNode(true);
            newFieldset.id = newIndex;
  
            // Modifica i nomi degli input all'interno del nuovo fieldset per includere il nuovo indice
            const inputs = newFieldset.querySelectorAll('input');
            inputs.forEach(input => {
                const name = input.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
                input.setAttribute('name', name);
                input.value = ''; // Svuota il valore dell'input
            });

            const selects = newFieldset.querySelectorAll('select');
            selects.forEach(select => {
                const name = select.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
                select.setAttribute('name', name);
                select.selectedIndex = 0; // Svuota il valore dell'input
            });
  
        
            // Aggiungi il nuovo fieldset al container degli ordini
            detailsContainer.appendChild(newFieldset);
  
            // Aggiorna lo stato dei link "rimuovi ordine"
            updateRemoveDetailLinks();

            
        }
    });
}
  
const updateRemoveDetailLinks = () => {
    // Trova il container degli ordini
    const detailsContainer = document.getElementById("ddtdetailsContainer");
    
    // Trova tutti gli ordini presenti
    const details = detailsContainer.querySelectorAll('.ddt-detail-fields');

    // Trova i link per rimuovere l'ordine
    const removeDetailLinks = document.querySelectorAll('.remove-ddt-detail-fields');

    // Verifica se ci sono più di un ordine
    if (details.length >= 2) {
        // Riattiva i link per rimuovere l'ordine
        removeDetailLinks.forEach(link => {
            link.classList.remove('disabled');
            link.removeAttribute('disabled');
            link.closest('.remove-ddt-detail-fields').style.display = "block"; // Mostra visivamente l'ordine
        });
    } else {
        // Disattiva i link per rimuovere l'ordine
        removeDetailLinks.forEach(link => {
            link.classList.add('disabled');
            link.setAttribute('disabled', 'disabled');
            link.closest('.remove-ddt-detail-fields').style.display = "none"; // Nasconde visivamente l'ordine
        });
    }
}
  
  export { addDdtDetail };