const addHours = () => {
    const addHoursButtons = document.querySelectorAll("#addHours");
    addHoursButtons.forEach(button => {
      button.addEventListener("click", () => { 
        const hoursContainer = button.closest(".order-details-fields").querySelector("#hoursContainer");
        const hoursFieldsets = hoursContainer.querySelectorAll('.hours-management-fields:not([style*="display: none"])');
        const lastHoursFieldset = hoursFieldsets[hoursFieldsets.length - 1];
        if (lastHoursFieldset) {
            const lastHoursId = lastHoursFieldset.id;
            const newHoursId = parseInt(lastHoursId) + 1;
            
            // Clona il primo hours-management-fields e sostituisci l'ID con il nuovo ID
            let newHoursFieldset = hoursFieldsets[0].cloneNode(true);
            newHoursFieldset.id = newHoursId;
            
            // Svuota tutti i campi di input del clone
            //const hoursInputs = newHoursFieldset.querySelectorAll('input');
            const newHoursInputs = newHoursFieldset.querySelectorAll('input');
            newHoursInputs.forEach(input => {
              input.name = input.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
                return g1 + n1 + g2 + "[" + newHoursId + "]" + g3;
              });
              input.value = ''; // Svuota il valore dell'input
            });

            //const hoursSelects = newHoursFieldset.querySelectorAll('select');
            const newHoursSelects = newHoursFieldset.querySelectorAll('select');
            newHoursSelects.forEach(select => {
              select.name = select.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
                return g1 + n1 + g2 + "[" + newHoursId + "]" + g3;
              });
              select.selectedIndex = 0; // Imposta l'indice selezionato al valore vuoto
            });
      
            // Aggiungi il nuovo hours-management-fields alla fine del container
            hoursContainer.appendChild(newHoursFieldset);

            // Aggiorna lo stato dei link "rimuovi ordine"
            updateRemoveHoursLinks();

        } else {
            console.log("Nessun fieldset hours-management trovato all'interno di hoursContainer.");
        }

      });
    });
  }

  const updateRemoveHoursLinks = () => {
  // Trova il container dei prodotti
  const hoursContainer = document.getElementById("hoursContainer");

  // Trova tutti i prodotti presenti
  const hours = hoursContainer.querySelectorAll('.hours-management-fields:not([style*="display: none"])');

  // Trova i link per rimuovere il prodotto
  const removeHoursLinks = document.querySelectorAll('.remove-hours-fields');

  // Verifica se ci sono più di un prodotto
  if (hours.length >= 2) {
    // Riattiva i link per rimuovere il prodotto
    removeHoursLinks.forEach(link => {
      link.classList.remove('disabled');
      link.removeAttribute('disabled');
      link.closest('.remove-hours-fields').style.display = "block"; // Mostra visivamente il prodotto
    });
  } else {
    // Disattiva i link per rimuovere il prodotto
    removeHoursLinks.forEach(link => {
      link.classList.add('disabled');
      link.setAttribute('disabled', 'disabled');
      link.closest('.remove-hours-fields').style.display = "none"; // Nasconde visivamente il prodotto
    });
  }
}

  export { addHours };
  