class RemoveDdtDetailFields {
  constructor() {
    this.iterateLinks();
  }

  iterateLinks() {
    document.addEventListener("click", (e) => {
      if (e.target && e.target.classList.contains("remove-ddt-detail-fields")) {
        this.handleClick(e.target, e);
        // Dopo la rimozione del prodotto, aggiorna lo stato dei link "Rimuovi Ordine"
        this.updateRemoveDetailLinks();
      }
    });
  }

  handleClick(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    let fieldParent = link.closest(".ddt-detail-fields");
    let deleteField = fieldParent ? fieldParent.querySelector('.destroy-detail') : null;
    if (deleteField) {
      deleteField.value = 1;
      // Nasconde visivamente il prodotto
      fieldParent.style.display = "none";
    }
  }

  updateRemoveDetailLinks() {
    // Trova il container dei prodotti
    const detailsContainer = document.getElementById("ddtdetailsContainer");

    // Trova tutti i prodotti presenti
    const details = detailsContainer.querySelectorAll('.ddt-detail-fields:not([style*="display: none"])');

    // Trova i link per rimuovere il prodotto
    const removeDetailLinks = document.querySelectorAll('.remove-ddt-detail-fields');

    // Verifica se ci sono più di un prodotto
    if (details.length >= 2) {
      // Riattiva i link per rimuovere il prodotto
      removeDetailLinks.forEach(link => {
        link.classList.remove('disabled');
        link.removeAttribute('disabled');
        link.closest('.remove-ddt-detail-fields').style.display = "block"; // Mostra visivamente il prodotto
      });
    } else {
      // Disattiva i link per rimuovere il prodotto
      removeDetailLinks.forEach(link => {
        link.classList.add('disabled');
        link.setAttribute('disabled', 'disabled');
        link.closest('.remove-ddt-detail-fields').style.display = "none"; // Nasconde visivamente il prodotto
      });
    }
  }
}

window.addEventListener("turbolinks:load", () => new RemoveDdtDetailFields());
