import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";

const addProduct = () => {
    const createButton = document.getElementById("addProduct");
    createButton.addEventListener("click", () => {
        // Trova il container degli ordini
        const productContainer = document.getElementById("productContainer");
        //console.log(productContainer);
        
        // Crea un nuovo indice incrementale per la nuova chiave dell'ordine
        const newIndex = productContainer.querySelectorAll('.product-details-fields').length;
        //console.log(newIndex);
        
        // Clona il primo fieldset e sostituisci l'ID con il nuovo ID
        const visibleFieldset = productContainer.querySelector('.product-details-fields:not([style*="display: none"])');
        //console.log(visibleFieldset);
        
        if (visibleFieldset) {
            const newFieldset = visibleFieldset.cloneNode(true);
            newFieldset.id = newIndex;
  
            // Modifica i nomi degli input all'interno del nuovo fieldset per includere il nuovo indice
            const inputs = newFieldset.querySelectorAll('input');
            inputs.forEach(input => {
                const name = input.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
                input.setAttribute('name', name);
                input.value = ''; // Svuota il valore dell'input
            });

            const selects = newFieldset.querySelectorAll('select');
            selects.forEach(select => {
                const name = select.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
                select.setAttribute('name', name);
                select.selectedIndex = 0; // Svuota il valore dell'input
            });
  
            //aggiorno gli indici degli hours_managements
            const productDatesContainer = newFieldset.querySelector("#productDatesContainer");
  
            // Svuota i campi del primo hoursFieldset rimasto
            const remainingDatesFieldset = productDatesContainer.querySelector('.product-dates-fields');
            if (remainingDatesFieldset) {
                const inputs = remainingDatesFieldset.querySelectorAll('input');
                inputs.forEach(input => {
                    
                    input.name = input.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
                        return g1 + "[" + newIndex + "]" + g2 + n2 + g3;
                    });
                    
                    input.value = '';
                });
  
                const selects = remainingDatesFieldset.querySelectorAll('select');
                selects.forEach(select => {
                    select.name = select.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
                        return g1 + "[" + newIndex + "]" + g2 + n2 + g3;
                    });
                    select.selectedIndex = 0;
                });
            } else {
                console.log("Nessun fieldset hours-management rimasto all'interno di hoursContainer.");
            }

        
            // Aggiungi il nuovo fieldset al container degli ordini
            productContainer.appendChild(newFieldset);
  
            // Inizializza flatpickr per i nuovi elementi aggiunti
            flatpickr(newFieldset.querySelectorAll('.mydate'), {
                locale: Italian,
                dateFormat: "d-m-Y",
                allowInput: true
            });

            // Aggiorna lo stato dei link "rimuovi ordine"
            updateRemoveProductLinks();
        }
    });
}
  
const updateRemoveProductLinks = () => {
    // Trova il container degli ordini
    const productContainer = document.getElementById("productContainer");

    // Trova tutti gli ordini presenti
    const products = productContainer.querySelectorAll('.product-details-fields:not([style*="display: none"])');

    // Trova i link per rimuovere l'ordine
    const removeProductLinks = document.querySelectorAll('.remove-product-fields');
    
    // Verifica se ci sono più di un ordine
    if (products.length >= 2) {
        // Riattiva i link per rimuovere l'ordine
        removeProductLinks.forEach(link => {
            link.classList.remove('disabled');
            link.removeAttribute('disabled');
            link.closest('.remove-product-fields').style.display = "block"; // Mostra visivamente l'ordine
        });
    } else {
        // Disattiva i link per rimuovere l'ordine
        removeProductLinks.forEach(link => {
            link.classList.add('disabled');
            link.setAttribute('disabled', 'disabled');
            link.closest('.remove-product-fields').style.display = "none"; // Nasconde visivamente l'ordine
        });
    }
}
  
  export { addProduct };