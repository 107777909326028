import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";

const addOrder = () => {
  const createButton = document.getElementById("addOrder");
  createButton.addEventListener("click", () => {
    // Trova il container degli ordini
    const orderContainer = document.getElementById("orderContainer");
    
    // Crea un nuovo indice incrementale per la nuova chiave dell'ordine
    const newIndex = orderContainer.querySelectorAll('.order-details-fields').length;

    // Clona il primo fieldset e sostituisci l'ID con il nuovo ID
    const visibleFieldset = orderContainer.querySelector('.order-details-fields:not([style*="display: none"])');
    
    if (visibleFieldset) {
      const newFieldset = visibleFieldset.cloneNode(true);
      newFieldset.id = newIndex;

      // Modifica i nomi degli input all'interno del nuovo fieldset per includere il nuovo indice
      const inputs = newFieldset.querySelectorAll('input');
      inputs.forEach(input => {
        const name = input.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
        input.setAttribute('name', name);
        input.value = ''; // Svuota il valore dell'input
      });

      //aggiorno gli indici degli hours_managements
      const hoursContainer = newFieldset.querySelector("#hoursContainer");
      const hoursFieldsets = hoursContainer.querySelectorAll('.hours-management-fields');
      // Elimina tutti gli hoursFieldset tranne quello con id 0
      hoursFieldsets.forEach(hoursFieldset => {
        if (hoursFieldset.id !== "0") {
          hoursFieldset.remove();
        }
      });

      // Svuota i campi del primo hoursFieldset rimasto
      const remainingHoursFieldset = hoursContainer.querySelector('.hours-management-fields');
      if (remainingHoursFieldset) {
        const inputs = remainingHoursFieldset.querySelectorAll('input');
        inputs.forEach(input => {
          input.name = input.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
            return g1 + "[" + newIndex + "]" + g2 + n2 + g3;
          });
          input.value = '';
        });

        const selects = remainingHoursFieldset.querySelectorAll('select');
        selects.forEach(select => {
          select.name = select.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
            return g1 + "[" + newIndex + "]" + g2 + n2 + g3;
          });
          select.selectedIndex = 0;
        });
      } else {
        console.log("Nessun fieldset hours-management rimasto all'interno di hoursContainer.");
      }
      const toggleHoursDiv = newFieldset.querySelector(`#hoursContainer-0`);
      toggleHoursDiv.id = `hoursContainer-${newIndex}`;

      // Aggiungi il nuovo fieldset al container degli ordini
      orderContainer.appendChild(newFieldset);

      // Aggiorna lo stato dei link "rimuovi ordine"
      updateRemoveOrderLinks();
      
      // Aggiungi event listener per i bottoni "addHours" nel nuovo fieldset
      addHoursListeners(newFieldset);

      addToggleHoursListener(newFieldset);

      // Inizializza flatpickr per i nuovi elementi aggiunti
      flatpickr(newFieldset.querySelectorAll('.mydate'), {
        locale: Italian,
        dateFormat: "d-m-Y",
        allowInput: true
      });

      const productsTab = document.getElementById('productsTab');
      productsTab.classList.add('disabled');
      productsTab.setAttribute('aria-selected', 'false');
      const paintingsTab = document.getElementById('paintingTab');
      paintingsTab.classList.add('disabled');
      paintingsTab.setAttribute('aria-selected', 'false');
      
    }
  });
}

const updateRemoveOrderLinks = () => {
  // Trova il container degli ordini
  const orderContainer = document.getElementById("orderContainer");
  
  // Trova tutti gli ordini presenti
  const orders = orderContainer.querySelectorAll('.order-details-fields');
  
  // Trova i link per rimuovere l'ordine
  const removeOrderLinks = document.querySelectorAll('.remove-order-fields');
  
  // Verifica se ci sono più di un ordine
  if (orders.length >= 2) {
    // Riattiva i link per rimuovere l'ordine
    removeOrderLinks.forEach(link => {
      link.classList.remove('disabled');
      link.removeAttribute('disabled');
      link.closest('.remove-order-fields').style.display = "block"; // Mostra visivamente l'ordine
    });
  } else {
    // Disattiva i link per rimuovere l'ordine
    removeOrderLinks.forEach(link => {
      link.classList.add('disabled');
      link.setAttribute('disabled', 'disabled');
      link.closest('.remove-order-fields').style.display = "none"; // Nasconde visivamente l'ordine
    });
  }
}

const addHoursListeners = (fieldset) => {
  const addHoursButtons = fieldset.querySelectorAll(".addHours"); // Assumendo che ".addHours" sia la classe corretta per i tuoi bottoni
  
  addHoursButtons.forEach(button => {
    button.addEventListener("click", () => { 
      
      const hoursContainer = button.closest(".order-details-fields").querySelector("#hoursContainer");
      
      const hoursFieldsets = hoursContainer.querySelectorAll('.hours-management-fields');
      const lastHoursFieldset = hoursFieldsets[hoursFieldsets.length - 1];
      if (lastHoursFieldset) {
          const lastHoursId = lastHoursFieldset.id;
          
          const newHoursId = parseInt(lastHoursId) + 1;
          

          // Clona il primo hours-management-fields e sostituisci l'ID con il nuovo ID
          let newHoursFieldset = hoursFieldsets[0].cloneNode(true);
          newHoursFieldset.id = newHoursId;
          
          // Svuota tutti i campi di input del clone
          //const hoursInputs = newHoursFieldset.querySelectorAll('input');
          const newHoursInputs = newHoursFieldset.querySelectorAll('input');
          newHoursInputs.forEach(input => {
            input.name = input.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
              return g1 + n1 + g2 + "[" + newHoursId + "]" + g3;
            });
            
            input.value = ''; // Svuota il valore dell'input
          });
    
          //const hoursSelects = newHoursFieldset.querySelectorAll('select');
          const newHoursSelects = newHoursFieldset.querySelectorAll('select');
          newHoursSelects.forEach(select => {
            select.name = select.name.replace(/^(.*?\[)(\d+)(\].*?\[)(\d+)(\].*)$/, function(match, g1, n1, g2, n2, g3) {
              return g1 + n1 + g2 + "[" + newHoursId + "]" + g3;
            });
            select.selectedIndex = 0; // Imposta l'indice selezionato al valore vuoto
          });

          // Aggiungi il nuovo hours-management-fields alla fine del container
          hoursContainer.appendChild(newHoursFieldset);

      } else {
          console.log("Nessun fieldset hours-management trovato all'interno di hoursContainer.");
      }

    });
  });
}

const addToggleHoursListener = (fieldset) => {
  const toggleButtons = fieldset.querySelectorAll('.toggle-hours');
  
  toggleButtons.forEach(button => {
    button.addEventListener('click', function() {
      const targetId = fieldset.id;
      const hoursContainer = fieldset.querySelector(`#hoursContainer-${targetId}`);
      
      const hoursContainerStyle = hoursContainer.style.display;
      //console.log(hoursContainerStyle);
      if (hoursContainer.style.display === 'none') {
        hoursContainer.style.display = 'block';
        button.textContent = '-';
      } else {
        hoursContainer.style.display = 'none';
        button.textContent = '+';
      }

    });
  });
};

export { addOrder, updateRemoveOrderLinks };


