  class RemoveHoursFields {
    constructor() {
      this.iterateLinks();
    }
  
    iterateLinks() {
      document.addEventListener("click", (e) => {
        if (e.target && e.target.classList.contains("remove-hours-fields")) {
          this.handleClick(e.target, e);
          // Dopo la rimozione del prodotto, aggiorna lo stato dei link "Rimuovi Ordine"
          this.updateRemoveHoursLinks();
        }
      });
    }
  
    handleClick(link, e) {
      if (!link || !e) return;
      e.preventDefault();
      let fieldParent = link.closest(".hours-management-fields");
      let deleteField = fieldParent ? fieldParent.querySelector('.destroy-hours') : null;
      if (deleteField) {
        deleteField.value = 1;
        // Nasconde visivamente il prodotto
        fieldParent.style.display = "none";
      }
    }
  
    updateRemoveHoursLinks() {
      
      // Trova il container dei prodotti
      const hoursContainer = document.getElementById("hoursContainer");
  
      // Trova tutti i prodotti presenti
      const hours = hoursContainer.querySelectorAll('.hours-management-fields:not([style*="display: none"])');
      
      // Trova i link per rimuovere il prodotto
      const removeHoursLinks = document.querySelectorAll('.remove-hours-fields');
  
      // Verifica se ci sono più di un prodotto
      if (hours.length >= 2) {
        // Riattiva i link per rimuovere il prodotto
        removeHoursLinks.forEach(link => {
          link.classList.remove('disabled');
          link.removeAttribute('disabled');
          link.closest('.remove-hours-fields').style.display = "block"; // Mostra visivamente il prodotto
        });
      } else {
        // Disattiva i link per rimuovere il prodotto
        removeHoursLinks.forEach(link => {
          link.classList.add('disabled');
          link.setAttribute('disabled', 'disabled');
          link.closest('.remove-hours-fields').style.display = "none"; // Nasconde visivamente il prodotto
        });
      }
    }
  }
  
  window.addEventListener("turbolinks:load", () => new RemoveHoursFields());