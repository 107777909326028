class RemovePaintingFields {
  constructor() {
    this.iterateLinks();
  }

  iterateLinks() {
    document.addEventListener("click", (e) => {
      if (e.target && e.target.classList.contains("remove-painting-fields")) {
        this.handleClick(e.target, e);
        // Dopo la rimozione del prodotto, aggiorna lo stato dei link "Rimuovi Ordine"
        this.updateRemovePaintingLinks();
      }
    });
  }

  handleClick(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    let fieldParent = link.closest(".painting-fields");
    let deleteField = fieldParent ? fieldParent.querySelector('.destroy-painting') : null;
    if (deleteField) {
      deleteField.value = 1;
      // Nasconde visivamente il prodotto
      fieldParent.style.display = "none";
    }
  }

  updateRemovePaintingLinks() {
    // Trova il container dei prodotti
    const paintingContainer = document.getElementById("paintingContainer");

    // Trova tutti i prodotti presenti
    const paintings = paintingContainer.querySelectorAll('.painting-fields:not([style*="display: none"])');

    // Trova i link per rimuovere il prodotto
    const removePaintingLinks = document.querySelectorAll('.remove-painting-fields');

    // Verifica se ci sono più di un prodotto
    if (paintings.length >= 2) {
      // Riattiva i link per rimuovere il prodotto
      removePaintingLinks.forEach(link => {
        link.classList.remove('disabled');
        link.removeAttribute('disabled');
        link.closest('.remove-painting-fields').style.display = "block"; // Mostra visivamente il prodotto
      });
    } else {
      // Disattiva i link per rimuovere il prodotto
      removePaintingLinks.forEach(link => {
        link.classList.add('disabled');
        link.setAttribute('disabled', 'disabled');
        link.closest('.remove-painting-fields').style.display = "none"; // Nasconde visivamente il prodotto
      });
    }
  }
}

window.addEventListener("turbolinks:load", () => new RemovePaintingFields());
