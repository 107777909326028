class removeFields {
  constructor() {
    this.iterateLinks();
  }

  iterateLinks() {
    document.addEventListener("click", (e) => {
      if (e.target && e.target.className == "remove-order-fields") {
        this.handleClick(e.target, e);
        // Dopo la rimozione dell'ordine, aggiorna lo stato dei link "rimuovi ordine"
        this.updateRemoveOrderLinks();

      }
    });
  }

  handleClick(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    let fieldParent = link.closest(".order-details-fields");
    let deleteField = fieldParent ? fieldParent.querySelector('.destroy-order') : null;
    if (deleteField) {
      deleteField.value = 1;
      // Nasconde visivamente l'ordine
      fieldParent.style.display = "none";
    }
  }

  updateRemoveOrderLinks() {

    const productsTab = document.getElementById('productsTab');
    productsTab.classList.add('disabled');
    productsTab.setAttribute('aria-selected', 'false');
    const paintingsTab = document.getElementById('paintingTab');
    paintingsTab.classList.add('disabled');
    paintingsTab.setAttribute('aria-selected', 'false');

    // Trova il container degli ordini
    const orderContainer = document.getElementById("orderContainer");
    
    // Trova tutti gli ordini presenti, escludendo quelli con style display: none
    const orders = orderContainer.querySelectorAll('.order-details-fields:not([style*="display: none"])');
    
    // Trova i link per rimuovere l'ordine
    const removeOrderLinks = document.querySelectorAll('.remove-order-fields');
    
    // Verifica se ci sono più di un ordine
    if (orders.length >= 2) {
      // Riattiva i link per rimuovere l'ordine e mostra visivamente gli ordini
      removeOrderLinks.forEach(link => {
        link.classList.remove('disabled');
        link.removeAttribute('disabled');
        link.closest('.remove-order-fields').style.display = "block"; // Mostra visivamente l'ordine
      });
    } else {
      // Disattiva i link per rimuovere l'ordine e nascondi visivamente gli ordini
      removeOrderLinks.forEach(link => {
        link.classList.add('disabled');
        link.setAttribute('disabled', 'disabled');
        link.closest('.remove-order-fields').style.display = "none"; // Nasconde visivamente l'ordine
      });
    }
  }
}

window.addEventListener("turbolinks:load", () => new removeFields());
